import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  production: true,
  version: '1.10.41',
  commitHash: 'ca230ec65e40b0bdc327a67fceb106c3d466e14a',
  sentry: '',
  boomerangApiUrl: 'http://localhost:5001',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'boomerang',
    bearerExcludedUrls: ['http://localhost:4201'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4201',
  },
};
